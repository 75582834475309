const logotext = "Gnarzo";
const meta = {
    title: "Gnarzo",
    description: "I’m Gnarzo Software Engineer _ software engineer in Tampa, Florida.",
};

const introdata = {
    title: "I’m Gnarzo",
    animated: {
        first: "I love coding",
        second: "I create music",
    },
    description: "Software Engineer creating music for fun with Logic Pro. Come with me on the journey!",
    your_img_url: "https://media.wired.com/photos/5a593a7ff11e325008172bc2/master/w_2560%2Cc_limit/pulsar-831502910.jpg",
};

const dataabout = {
    title: "a_bit_about_myself",
    aboutme: "Software Engineer creating music for fun with Logic Pro. Come with me on the journey!",
};
const songs = [{
        jobtitle: "Joji",
        where: "Glimpse of Us",
        date: "2022",
    },
    {
        jobtitle: "Ella Mai",
        where: "Trying",
        date: "2022",
    },
    {
        jobtitle: "Harry Styles",
        where: "As It Was",
        date: "2022",
    },
];

const genres = [{
        name: "R&B",
        value: 80,
    },
    {
        name: "Hip Hop",
        value: 70,
    },
    {
        name: "Lofi",
        value: 85,
    },
    {
        name: "Soul",
        value: 50,
    }
];

const inspiration = [{
        title: "Joji",
        description: "George Kusunoki Miller, known professionally as Joji and formerly as Filthy Frank and Pink Guy, is a Japanese singer, rapper, comedian, and former YouTuber. Miller's music has been described as a mix between R&B, lo-fi, and trip hop.",
    },
    {
        title: "Ella Mai",
        description: "Ella Mai Howell is an English singer-songwriter. Her musical career began at London's British and Irish Modern Music Institute in 2014, during which time she auditioned as part of a trio on the 11th season of The X Factor.",
    },
    {
        title: "Harry Styles",
        description: "Harry Edward Styles is an English singer, songwriter and actor. His musical career began in 2010 as a solo contestant on the British music competition series The X Factor.",
    }
];

const dataportfolio = [{
        img: "https://i1.sndcdn.com/artworks-GNMQFypcxRDmhzCb-N4VIXw-t500x500.jpg",
        desctiption: "simple_and_clean",
        link: "#",
    },
];

const contactConfig = {
    YOUR_EMAIL: "quantribution@gmail.com",
    description: "Just send me a message here! :)",
    YOUR_SERVICE_ID: "service_7dnn4bg",
    YOUR_TEMPLATE_ID: "template_370ujin",
    YOUR_USER_ID: "B9cFjqTm-T1atW5FH",
};

const socialprofils = {
    github: "https://github.com",
    spotify: "https://spotify.com",
    youtube: "https://youtube.com",
    twitch: "https://twitch.com",
};
export {
    meta,
    dataabout,
    dataportfolio,
    songs,
    genres,
    inspiration,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
};